<template>
    <div>
        <div class="SecondTitleRegistration" style="display: flex;justify-content: center;">
            <span>
                {{ $t('registration.counter4PH') }}: {{ this.storeName }}
            </span>
        </div>
        <van-cell-group style="margin-top: 20px">
            <van-form @submit="submit()">
                <van-field required v-model="info.title"
                    :rules="[{ required: true, message: $t('registration.rulerSalutation'), trigger: 'onBlur' }]">
                    <template #input>
                        <van-dropdown-menu>
                            <van-dropdown-item v-model="info.title" :options="titleOption"
                                :title="titleOption.find(i => i.value == info.title)?.text || $t('registration.salutation')">
                            </van-dropdown-item>
                        </van-dropdown-menu>
                    </template>
                </van-field>

                <van-field required v-model="info.firstName" :placeholder="this.$t('registration.formValueFirstName')"
                    :rules="[{ required: true, message: $t('registration.rulerFirstname'), trigger: 'onBlur' }]" />

                <van-field required v-model="info.lastName" :placeholder="this.$t('registration.formValueLastName')"
                    :rules="[{ required: true, message: $t('registration.rulerLastname'), trigger: 'onBlur' }]" />

                <div style="display: flex;align-items: center;">
                    <div style="margin-left:10px">{{ this.info.areaCode }}</div>
                    <van-field required type="number" style="border-bottom: 1px solid #f5f6f8" maxlength="10"
                        v-model="info.mobile" :placeholder="$t('registration.formValueMobileNumber4ph')"
                        :rules="[{ pattern: /^([0-9]{10})$/, required: true, message: $t('registration.rulerMobile'), trigger: 'onBlur' }]" />
                </div>

                <van-field v-show="showWechatID" v-model="info.wechatID" :placeholder="$t('registration.formValueWechatID')" />

                <van-field class="requiredEmail" v-model="info.email" :placeholder="$t('registration.formValueEmail')"
                    :rules="[{ pattern: info.email != '' && info.email.trim().length > 0 && /^[0-9A-Za-z_]+([-+.][0-9A-Za-z_]+)*@[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*\.[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*$/, message: $t('registration.rulerEmail'), trigger: 'onBlur' }]" />

                <van-field :label="this.$t('registration.formValueDateOfBirth')"
                    :error-message="errMessageBirth">
                    <template #input>
                        <BirthView @change="validatorBirth()" :country="'PH'" :birth="info.birth" :requiredMonth="true"/>
                    </template>
                </van-field>

                <!-- <van-field class="date-of-birth" :label="this.$t('registration.formValueDateOfBirth')"
                    :error-message="errMessageBirth">
                    <template #input>
                        <BirthView @change="validatorBirth()" :country="'PH'" :birth="info.birth" />
                    </template>
                </van-field> -->

                <van-field>
                    <template #input>
                        <van-dropdown-menu>
                            <van-dropdown-item :title="ageGroupOptions.find(i => i.value == info.ageGroup)?.text || $t('registration.ageGroup')" v-model="info.ageGroup" :options="ageGroupOptions" />
                        </van-dropdown-menu>
                    </template>
                </van-field>

                <div class="second-tips">
                    <div>
                        <input class="agreeCheckBox" type="checkbox" v-model="info.marketingAgree" />
                    </div>
                    <div class="agreeStatement" v-html='marketingAgreeConsent'/>
                </div>

                <div class="second-tips">
                    <div>
                        <input class="agreeCheckBox" type="checkbox" v-model="info.privacyPolicyAgree" />
                    </div>
                    <div class="agreeStatementMandatory" v-html='privacyPolicyAgreeConsent'/>
                </div>

                <div style="margin-top: 20px">
                    <van-button size="large" :disabled="!(info.privacyPolicyAgree)" round :loading=submitLoading
                        class="button-submit">
                        {{ $t("registration.submit") }}
                    </van-button>
                </div>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script>
import {
    Button, Cell, CellGroup, DropdownItem, DropdownMenu,
    Field, Switch, Checkbox, CheckboxGroup, Form, Radio, RadioGroup, DatetimePicker, Popup, Dialog
} from 'vant';
import { getSession, relateAddOrUpdate } from '@/api/eformApi';
import mixins from "./../mixin/Registration.js";
export default {
    name: "Registration",
    mixins: [mixins],
    components: {
        [Popup.name]: Popup,
        [DatetimePicker.name]: DatetimePicker,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        [Switch.name]: Switch,
        [Cell.name]: Cell,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Button.name]: Button,
        [Field.name]: Field,
        [Dialog.Component.name]: Dialog.Component,
        BirthView: () => import('./../components/BirthView'),
    },
    props: {
        brandConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            info: {
                title: '',
                firstName: '',
                lastName: '',
                areaCode: '+63',
                mobile: '',
                wechatID: '',
                email: '',
                birth: {
                    day: '',
                    month: '',
                    year: ''
                },
                privacyPolicyAgree: false,
                marketingAgree: false,
                ageGroup: ''
            },
            submitLoading: false,
            errMessageBirth: '',
            errMessageEmail: '',
        };
    },
    computed: {
        marketingAgreeConsent() {
            let brand = this.$route.params.brand;
            if (brand == "BBC") {
                return this.$t("registration.agreeConditionsPH.conditionBBC").format(this.otherBrands)
            } else {
                return this.$t("registration.agreeConditionsPH.condition2").format(this.brandConfig.display,this.otherBrands)
            }
        },
        privacyPolicyAgreeConsent() {
            return this.$t("registration.agreeConditionsPH.condition1").format(this.privacyPolicy)
        },
        ageGroupOptions() {
            return [
                { text: '18-25', value: '{"from":18,"to":25}' },
                { text: '26-35', value: '{"from":26,"to":35}' },
                { text: '36-45', value: '{"from":36,"to":45}' },
                { text: '46-55', value: '{"from":46,"to":55}' },
                { text: '56+', value: '{"from":56,"to":99}' },
            ]
        },
        titleOption() {
            return [
                { text: this.$t('registration.mrs'), value: "Mrs." },
                { text: this.$t('registration.ms'), value: "Ms" },
                { text: this.$t('registration.mr'), value: "Mr." },
                { text: this.$t('registration.othe'), value: "OTHER." }
            ]
        },
        showWechatID() { 
            const wechatIDRequiredBrands = ['TF', 'LM'];
            let brand = this.$route.params.brand;
            return wechatIDRequiredBrands.includes(brand);
        },
        otherBrands() { 
            return `<span><a href="https://www.elcompanies.com/en/our-brands">${this.$t("registration.agreeConditionsPH.condition3")}</a></span>`;
        },
        privacyPolicy() {
            if (this.brandConfig.privacyPolicy) {
                return `<span><a href="${this.brandConfig.privacyPolicy}">${this.$t("registration.privacyPolicy")}</a></span>`;
            } else {
                return `<span>${this.$t("registration.privacyPolicy")}</span>`;    
            }
        }
    },
    methods: {
        validatorFirstName() {
            return this.info.firstName != '' && this.info.firstName.trim() != ''
        },
        validatorLastName() {
            return this.info.lastName != '' && this.info.lastName.trim() != ''
        },
        validatorBirth() {
            this.errMessageBirth = '';
            // if (this.info.birth.day == '') {
            //     this.errMessageBirth = this.$t('registration.rulerBirthday4PH');
            //     return false;
            // }
            if (this.info.birth.month == '') {
                this.errMessageBirth = this.$t('registration.rulerBirthMonth4PH');
                return false;
            }
            // if (this.info.birth.year == '') {
            //     this.errMessageBirth = this.$t('registration.rulerBirthYear4PH');
            //     return false;
            // }
            return true;
        },
        validatorEmail() {
            this.errMessageEmail = '';
            const regex = /^[0-9A-Za-z_]+([-+.][0-9A-Za-z_]+)*@[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*\.[0-9A-Za-z_]+([-.][0-9A-Za-z_]+)*$/;
            if (this.info.email.trim() != "" && !regex.test(this.info.email.trim())) {
                this.errMessageEmail = this.$t("registration.rulerEmail")  
                return false;
            }
            return true;
        },
        validatorForm() {
            return  this.storeName != '' & this.validatorFirstName()
                & this.validatorLastName() & this.info.areaCode != ''
                & this.validatorBirth() & this.validatorEmail();
        },
        submit() {
            this.submitLoading = true;
            if (!this.validatorForm()) {
                this.submitLoading = false;
                return;
            }
            if (this.info.email == '') {
                Dialog.confirm({
                    message: this.$t('registration.emailConfirm'),
                    confirmButtonText: this.$t('registration.yes'),
                    cancelButtonText: this.$t('registration.no'),
                }).then(() => {
                    this.doSubmit();
                }).catch(() => {
                    this.submitLoading = false;
                });
                return;
            }
            this.doSubmit();
        },
        doSubmit() {
            this.info.firstName = this.info.firstName.trim()
            this.info.lastName = this.info.lastName.trim()
            if (this.info.ageGroup != '') {
                const ageGroupObj = JSON.parse(this.info.ageGroup)
                this.info.ageFrom = ageGroupObj.from
                this.info.ageTo = ageGroupObj.to
            }
            getSession({
                ...this.info,
                country: this.$route.params.countryPath,
                brand: this.$route.params.brand,
            }).then(res => {
                if (res.success) {
                    relateAddOrUpdate({
                        ...this.info,
                        preLanguage: this.$root.$i18n.locale,
                        country: this.$route.params.countryPath,
                        storeId: this.storeId,
                        storeName: this.storeName,
                        brand: this.$route.params.brand,
                        sessionKey: res.data
                    }).then(res => {
                        this.submitLoading = false;
                        if (res.success) {
                            this.$router.push({
                                name: 'QRcodes',
                                params: {
                                    custId: res.data.frontiersVipNo,
                                    storeId: this.storeId,
                                    lang: this.$route.params.lang,
                                    brand: this.$route.params.brand,
                                    country: this.$route.params.countryPath
                                },
                                query: {
                                    storeId: this.$route.query.storeId,
                                }
                            })
                        } else if (res.data.exist) {
                            Dialog.alert({
                                message: this.$t('registration.alreadyRMobileAndLastName'),
                                confirmButtonText: this.$t('registration.confirm')
                            })
                            this.submitLoading = false;
                            return;
                        }
                    })
                }
            })
            
        },
    }
}
</script>

<style scoped>
* {
    font-family: Helvetica-Regular !important;
}

input.agreeCheckBox {
    transform: scale(1.5);
    margin-top: 33%
}

.agreeStatementMandatory::before {
    padding-right: 2px;
    color: #ee0a24;
    font-size: 14px;
    content: "*";
    z-index: 100;
}

 .agreeStatementMandatory {
    margin-left: 10px;
}

.agreeStatement {
    margin-left: 10px;
}

.second-tips {
    display: inline-flex;
    font-size: 14px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 20px;
    line-height: 20px;
    width: 100%;
}

.date-of-birth::before {
    position: absolute;
    left: 9px;
    top: 10px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
    z-index: 100;
}

.button-submit {
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 10px;
}

/deep/ .van-field--error .van-field__control::placeholder {
    color: #c8c9cc !important;
}

/deep/ .van-field--error .van-field__control {
    color: #323233 !important;
}

/deep/ .van-dropdown-menu__title {
    padding-left: 0 !important;
}

.requiredEmail::before {
    position: absolute;
    left: 9px;
    top: 10px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
    z-index: 100;
}

/*下拉框样式*/
>>>.van-dropdown-menu {
    width: 100%;
}

>>>.van-dropdown-menu__title {
    /* position: unset;
        color: #afafaf; */
    font-size: 14px;
}

>>>.van-dropdown-menu__item {
    justify-content: left;
}

>>>.van-dropdown-menu__bar {
    height: unset;
    box-shadow: unset;
}

>>>.van-dialog {
    border-radius: 5px;
}

/*>>> .van-popup--top {*/
/*    width: 90%;*/
/*    margin: 0 5%;*/
/*}*/
/*>>> .van-dropdown-item__option {*/
/*    margin-left: 10px;*/
/*}*/
>>>.van-checkbox {
    /* margin-top: -10% */
}
</style>

<style lang="less" scoped>
.age .van-radio--horizontal {
    margin-bottom: 5px !important;
}

/deep/ .item1::before {
    position: absolute;
    left: -4px;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
}

/deep/ .item2::before {
    position: absolute;
    left: 33%;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: '*';
}
</style>
